import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet-async";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero3 from "components/SectionHero/SectionHero3";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";

//
import logo1 from "images/logos/nomal/1.png";
import logo1Dark from "images/logos/dark/1.png";
//
import logo2 from "images/logos/nomal/2.png";
import logo2Dark from "images/logos/dark/2.png";
//
import logo3 from "images/logos/nomal/3.png";
import logo3Dark from "images/logos/dark/3.png";
//
import logo4 from "images/logos/nomal/4.png";
import logo4Dark from "images/logos/dark/4.png";
//
import logo5 from "images/logos/nomal/5.png";
import logo5Dark from "images/logos/dark/5.png";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Event Go | About us</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />
      {/* container py-16 lg:py-28 space-y-16 lg:space-y-28 */}
      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        <SectionHero3 className="" />
        <SectionHero
          rightImg={rightImg}
          heading="About Us"
          btnText=""
          subHeading="EventGo charts novel paths in the event industry, leveraging pioneering technology to enhance event visibility, streamline ticketing, and deliver impactful experiences affordably. Our data-driven approach sets trends for sustainable event management, elevating each gathering into a lasting memory. We're not just part of the event ecosystem; we're redefining it, merging strategic production with seamless execution."
        />

      

      <div className="ncSectionLogos grid grid-cols-3 lg:grid-cols-5 gap-5 sm:gap-16">
          <div className="flex items-end justify-center">
            <img className="block dark:hidden" src={logo1} alt="logo1" />
            <img className="hidden dark:block" src={logo1Dark} alt="logo1" />
          </div>
          <div className="flex items-end justify-center">
            <img className="block dark:hidden" src={logo4} alt="logo4" />
            <img className="hidden dark:block" src={logo4Dark} alt="logo4" />
          </div>
          <div className="flex items-end justify-center">
            <img className="block dark:hidden" src={logo2} alt="logo2" />
            <img className="hidden dark:block" src={logo2Dark} alt="logo2" />
          </div>
          <div className="flex items-end justify-center">
            <img className="block dark:hidden" src={logo3} alt="logo3" />
            <img className="hidden dark:block" src={logo3Dark} alt="logo3" />
          </div>

          <div className="flex items-end justify-center">
            <img className="block dark:hidden" src={logo5} alt="logo5" />
            <img className="hidden dark:block" src={logo5Dark} alt="logo5" />
          </div>
        </div>

        <SectionStatistic />

        <SectionFounder />
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageAbout_" />
        </div>

        

        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageAbout;
